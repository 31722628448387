import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Вітаємо у Творчі Петлі
			</title>
			<meta name={"description"} content={"Розкривайте таємниці рукоділля – В'язання, вишивання та багато іншого з Творчими Петлями"} />
			<meta property={"og:title"} content={"Home | Вітаємо у Творчі Петлі"} />
			<meta property={"og:description"} content={"Розкривайте таємниці рукоділля – В'язання, вишивання та багато іншого з Творчими Петлями"} />
			<meta property={"og:image"} content={"https://nulvorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nulvorich.com/img/4634647.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nulvorich.com/img/4634647.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nulvorich.com/img/4634647.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="150px 0 150px 0"
			min-height="60vh"
			background="linear-gradient(180deg,rgba(247, 251, 255, 0.8) 0%,rgba(255, 255, 255, 0.5) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://nulvorich.com/img/1.jpg) 0% 50% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline1"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Творчі Петлі
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Зануртесь у світ рукоділля, де традиції переплітаються з сучасністю. Творчі Петлі – це Ваш особистий простір для творчості, де кожен може знайти щось для себе, незалежно від досвіду та умінь. Від вишуканих технік вишивки до затишного в'язання – наші курси надихнуть Вас на нові творчі звершення.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--darkL2">
			Чому варто обирати Творчі Петлі?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://nulvorich.com/img/2.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="10px 25px 0px 25px"
						>
							<Text margin="10px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
							Доступність та зручність
							</Text>
							<Text margin="0px 0px 0px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
							Наші курси доступні будь-коли та з будь-якого місця, дозволяючи вам навчатися у власному темпі.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://nulvorich.com/img/3.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="10px 25px 0px 25px"
						>
							<Text margin="10px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
							Індивідуальний підхід
							</Text>
							<Text margin="0px 0px 0px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
							Ми враховуємо рівень навичок кожного учня, пропонуючи курси від початкового до просунутого рівня.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://nulvorich.com/img/4.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="10px 25px 0px 25px"
						>
							<Text margin="10px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
							Спільнота творців
							</Text>
							<Text margin="0px 0px 0px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
							Приєднуйтесь до нашої активної спільноти однодумців, де можна обмінюватися ідеями та отримувати підтримку.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 80px 0" md-padding="80px 0 80px 0" sm-padding="50px 0 50px 0" quarkly-title="CTA/Lead/Form-3">
			<Box
				min-width="100px"
				min-height="100px"
				padding="80px 80px 80px 80px"
				border-radius="40px"
				md-padding="50px 50px 50px 50px"
				sm-padding="50px 30px 50px 30px"
				background="--color-dark"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--light"
					text-align="center"
					font="normal 700 38px/1.2 --fontFamily-sans"
					lg-margin="0px 0 20px 0"
					sm-font="normal 700 36px/1.2 --fontFamily-sans"
					sm-text-align="left"
				>
					Дізнайтеся більше про Творчі Петлі
				</Text>
				<Text
					margin="0px 40px 50px 40px"
					text-align="center"
					font="--lead"
					color="rgba(255, 255, 255, 0.8)"
					lg-margin="0px 0 50px 0"
					sm-text-align="left"
				>
					У Творчих Петлях ми пропонуємо широкий спектр онлайн курсів, що охоплюють все – від основ вишивки до складних проєктів в'язання. Наші майстер-класи розроблені так, щоб надихати, розвивати майстерність та допомогти вам створити щось унікальне та особливе власними руками.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					justify-content="center"
					sm-flex-direction="column"
					sm-align-items="center"
				>
					<Button
						background="rgba(247, 251, 255, 0)"
						href="/about"
						type="link"
						text-decoration-line="initial"
						font="normal 400 17px/1.5 --fontFamily-sans"
					>
						Більше про нас &gt;&gt;
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});